const questions = [
  // 1 Слайд \\ Перу [1]
  {
    questionText:
      "O Peru é um país montanhoso onde as culturas indígenas e dos colonizadores europeus estão profundamente entrelaçadas.",
    answerOptions: [
      {
        answerText:
          "O Peru é o terceiro maior país do continente sul-americano em termos de território, depois do Brasil e da Argentina.",
        answerResult: "Certo! Esse país tem a área de 1.285.000 km²",
        result: true,
      },
      {
        answerText:
          "As lhamas selvagens do Peru foram domesticadas somente em meados do século XX.",
        answerResult:
          "Nada disso. As lhamas foram domesticadas há mais de 3.000 anos.",
        result: false,
      },
      {
        answerText:
          "A touca nacional dos peruanos, um gorro de malha com protetores de orelha, é chamado de quipu.",
        answerResult:
          "Não, não é. Quipu é um sistema antigo de escrita constituído por um conjunto de cordões nos quais eram dados nós. E o gorro é chamado de chullo. ",
        result: false,
      },
      {
        answerText:
          "O deserto de Nazca abriga as misteriosas pirâmides, o monumento de uma antiga civilização.",
        answerResult:
          "Não há dúvida de que os antigos peruanos deixaram sua marca no deserto de Nazca. Mas não se trata de pirâmides, e sim de geoglifos, os desenhos gigantes na terra.",
        result: false,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд \\ Аргентина [4]

  {
    questionText:
      "Viajando pela Argentina, você pode ver tanto macacos selvagens na mata do norte quanto pinguins na gelada costa sul.",
    answerOptions: [
      {
        answerText:
          "O nome da capital da Argentina significa Belas Flores em espanhol.",
        answerResult:
          "A capital da Argentina é Buenos Aires, que significa Ar Puro.",
        result: false,
      },
      {
        answerText:
          "A seleção argentina de futebol ganhou a Copa do Mundo mais vezes do que qualquer outra.",
        answerResult:
          "Infelizmente para os argentinos, sua equipe nacional só ganhou a Taça da Copa três vezes. A Itália e a Alemanha ganharam o troféu quatro vezes, enquanto o Brasil o conquistou cinco vezes.",
        result: false,
      },
      {
        answerText: "A maioria dos argentinos é vegetariana.",
        answerResult:
          "Exatamente o contrário. A Argentina ocupa o primeiro lugar do planeta em consumo de carne. Toda a culinária nacional é baseada nela.",
        result: false,
      },
      {
        answerText:
          "O chá mate tradicional é tomado pelos argentinos através de um canudo de recipientes especiais chamados calabazas.",
        answerResult:
          "É isso mesmo! E o canudo com a peneira é chamado de bombilla.",
        result: true,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд \\ Вьетнам [1]
  {
    questionText:
      "Esse país de selvas e grandes cidades se estende de norte a sul, às margens do Mar do Sul da China. O que sabemos sobre ele? Vamos verificar!",
    answerOptions: [
      {
        answerText:
          "No sul do Vietnã, há um pequeno deserto com dunas de areia.",
        answerResult:
          "Isso é verdade. É possível andar de quadriciclo nas dunas ou simplesmente descer delas como se fossem um escorregador.",
        result: true,
      },
      {
        answerText:
          "O Vietnã ocupa o primeiro lugar no ranking mundial de produção de café.",
        answerResult:
          "Surpreendentemente, apenas o Brasil conseguiu superar o Vietnã na produção de café. Portanto, esse país ocupa um honroso segundo lugar.",
        result: false,
      },
      {
        answerText:
          "O dinheiro vietnamita, o dong, é feito de tecido para maior durabilidade.",
        answerResult:
          "Os dongs de fato não rasgam, não ficam sujos nem molhados. Mas eles são feitos de plástico.",
        result: false,
      },
      {
        answerText:
          "A famosa sopa de macarrão vietnamita Pho vem dos tempos antigos.",
        answerResult:
          "A história é diferente. Os vietnamitas começaram a consumir carne bovina, que é usada para fazer caldo para macarrão de arroz, seguindo o exemplo dos europeus no século XIX. Antes disso, o gado era usado apenas para arar e transportar mercadorias.",
        result: false,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд \\ Малайзия [3]
  {
    questionText:
      "Esse país tem muitas coisas para nos surpreender. Não é à toa que a Malásia está entre os dez países mais visitados pelos turistas. E aqui estão algumas afirmações sobre esse país. Mas apenas uma delas é verdadeira.",
    answerOptions: [
      {
        answerText:
          "No território da ilha de Kalimantan fica a fronteira entre os dois países.",
        answerResult:
          "Entre os três. Partes dessa ilha pertencem não apenas à Indonésia e à Malásia, mas também ao Brunei.",
        result: false,
      },
      {
        answerText: "A Malásia cultiva até 50 tipos de maçãs.",
        answerResult:
          "Embora na Malásia seja possível encontrar as frutas mais sofisticadas, as maçãs comuns não crescem aqui. Elas são sempre importadas.",
        result: false,
      },
      {
        answerText:
          "Na Malásia existe uma multa para quem transitar pela cidade em um elefante.",
        answerResult:
          "Pois é. Aliás, é muito mais conveniente andar de carro ou de motocicleta, que são muitos na Malásia.",
        result: true,
      },
      {
        answerText:
          "A Malásia é o lar dos menores ursos do mundo, os biruangs. Eles têm aproximadamente o tamanho de um cachorro médio.",
        answerResult:
          "Não é bem assim. O biruang é de fato o menor urso. Mas ele pode crescer até um metro e meio de altura e pesar até 65 quilos.",
        result: false,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд \\ Бразилия [2]
  {
    questionText:
      "Samba, anaconda, capoeira - tudo isso está presente no Brasil coberto pela floresta tropical. Por que mais esse país é famoso? Escolha a afirmação correta.",
    answerOptions: [
      {
        answerText:
          "A capital do Brasil é São Paulo. Essa cidade foi projetada pelo arquiteto Lúcio Costa no formato de uma borboleta ou pássaro voador.",
        answerResult:
          "A planta da capital de fato é parecida com um pássaro, ou até mesmo com um avião. Mas o nome dessa cidade é Brasília.",
        result: false,
      },
      {
        answerText:
          "O Brasil tem tanta cana-de-açúcar que ela é usada até mesmo para produzir um combustível que pode substituir os derivados de petróleo.",
        answerResult:
          "Correto! O Brasil é o maior produtor de cana-de-açúcar do mundo. E também de laranjas.",
        result: true,
      },
      {
        answerText: "O famoso carnaval brasileiro dura um mês inteiro.",
        answerResult:
          "Por mais que os habitantes locais e os turistas queiram prolongar as festividades, o Carnaval dura apenas cinco dias. Ele é seguido pela Quaresma.",
        result: false,
      },
      {
        answerText:
          "Já sabemos que os brasileiros são jogadores de futebol incomparáveis. É claro que são, pois o jogo é mencionado até mesmo em seu hino nacional.",
        answerResult:
          "Isso não é verdade. A letra do hino do Brasil fala principalmente de amor à pátria, de um passado glorioso e de um futuro brilhante.",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 7 слайд \\ Индонезия [3]
  {
    questionText:
      "Bem, isso deve ser fácil. Qualquer colegial indonésio pode identificar qual dessas quatro afirmações é verdadeira e qual é nossa ficção insidiosa.",
    answerOptions: [
      {
        answerText:
          "A Indonésia possui 9 patrimônios mundiais da UNESCO. Todos eles são naturais, como, por exemplo, o Parque Nacional Komodo.",
        answerResult:
          "Apenas metade desses objetos é natural. O restante é feito por humanos, como os complexos de templos de Borobudur e Prambanan.",
        result: false,
      },
      {
        answerText: "Os indonésios gostam de dormir.",
        answerResult:
          "Talvez, mas a maioria das pessoas se levanta bem cedo, geralmente antes das 5:00. De manhã, é possível fazer as orações, chegar ao trabalho antes dos engarrafamentos ou ir para a escola.",
        result: false,
      },
      {
        answerText: "A Indonésia é a terra dos mototáxis.",
        answerResult:
          "É um fato. Devido ao tráfego intenso, esse modo de transporte manobrável é muito popular. ",
        result: true,
      },
      {
        answerText:
          "Os gatos parecem estar em toda parte na Indonésia, mas, na realidade, sua população não passa de 1.000.000.",
        answerResult:
          "Um milhão de gatos para um país tão grande é muito pouco. Há mais de 30.000.000 de gatos lá. E isso sem contar os gatos de madeira da ilha de Bali.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },

  // 8 слайд \\ Мексика [2]
  {
    questionText:
      "Quando falamos sobre o México, necessariamente imaginamos pessoas com sombreros e um deserto repleto de cactos. Mas será que esses estereótipos são tão justos assim? Vamos descobrir!",
    answerOptions: [
      {
        answerText:
          "Não há táxis na capital do México, mas o transporte público é bem desenvolvido.",
        answerResult:
          "Pelo contrário. A Cidade do México é a primeira do mundo em número de táxis. Há mais de 100.000 desses carros lá.",
        result: false,
      },
      {
        answerText:
          "A pipoca foi inventada no México cerca de 4.000 anos atrás.",
        answerResult:
          "Certo. Os índios aqueciam um tipo especial de milho e usavam os grãos abertos como alimento ou decoração.",
        result: true,
      },
      {
        answerText:
          "Um dos símbolos do México, os cães Chihuahua foram trazidos pelos conquistadores espanhóis, que usavam os cachorros desta raça para capturar ratos nos navios.",
        answerResult:
          "Os Chihuahuas são cem por cento mexicanos. Esses pequeninos corajosos são descendentes da extinta raça Techichi, que as tribos mexicanas consideravam sagrada.",
        result: false,
      },
      {
        answerText: "Os cactos no México são usados apenas para fazer tequila.",
        answerResult:
          "É claro que não. Diferentes variedades de cactos são usadas para fazer comida, remédios, shampoos, corantes e até mesmo tecidos.",
        result: false,
      },
    ],
    image: "images/07.webp",
  },
];

export default questions;
